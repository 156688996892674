import React from "react";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./home";
import Colleagues from "../Colleagues";
import Shop from "../Shop";
import Admin from "../Admin";


export default function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/colleagues" element={<Colleagues />} />
                <Route path="/shop" element={<Shop />} />
                <Route path="/admin" element={<Admin />} />

            </Routes>
        </Router>


    )
}
