import React, {useEffect, useState} from "react";
import Header from "../Header";
import AddGreetingShop from "./addGreetingShop";

import Accordion from 'react-bootstrap/Accordion';
import CardShop from "./cardShop";


export default function Shop() {
    const user = localStorage.getItem('user');
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://electronic-queue.ru/aip/selectShop.php');
                if (!response.ok) {
                    throw new Error('Ошибка при загрузке данных');
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

    }, []);

    return (
        <>
            <Header />
            <div>
                Поздравление от коллег магазина
            </div>
            {user === 'admin' && (
            <Accordion defaultActiveKey={['1']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Добавить новое поздравление</Accordion.Header>
                    <Accordion.Body>
                        <AddGreetingShop />
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            )}


            <CardShop data ={data} />

        </>
    )
}