import React from "react";
import Header from "../Header";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';

import './indexCss.css'
import leroy from './img/photo_2024-03-18_03-36-34.jpg'
import dr from './img/76-20220105_180015-2048x509.png'

export default function Home() {


    return (
        <>
            <Header />
            <Container fluid>
                <Row>
                    <Col>
                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', height: '90vh' }}>

                            <Image src={dr} thumbnail style={{ width: 'auto', height: '200px' }} /><br />
                            <Image src={leroy} thumbnail style={{ width: 'auto', height: '600px' }} />

                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="slider-wrapper">
                <div className="slider-container">
                    <Container fluid="sm, md, lg, xl, xxl">
                        <Row>
                            <Col>

                                <Carousel data-bs-theme="dark">
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src="https://happy-birthday-leroymerlin.ru/img/photo_2024-03-20_10-56-41.jpg"
                                            alt="First slide"
                                            style={{ width: 'auto', height: 'auto', display: 'flex', justifyContent: 'center' }}
                                        />
                                        <Carousel.Caption>
                                            <h5>First slide label</h5>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src="https://happy-birthday-leroymerlin.ru/img/photo_2024-03-20_10-56-46.jpg"
                                            alt="First slide"
                                            style={{ width: 'auto', height: 'auto', display: 'flex', justifyContent: 'center' }}
                                        />
                                        <Carousel.Caption>
                                            <h5>First slide label</h5>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src="https://happy-birthday-leroymerlin.ru/img/photo_2024-03-20_10-56-50.jpg"
                                            alt="First slide"
                                            style={{ width: 'auto', height: 'auto', display: 'flex', justifyContent: 'center' }}
                                        />
                                        <Carousel.Caption>
                                            <h5>First slide label</h5>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                    <Carousel.Item>
                                        <img
                                            className="d-block w-100"
                                            src="https://happy-birthday-leroymerlin.ru/img/photo_2024-03-20_10-56-54.jpg"
                                            alt="First slide"
                                            style={{ width: 'auto', height: 'auto', display: 'flex', justifyContent: 'center' }}
                                        />
                                        <Carousel.Caption>
                                            <h5>First slide label</h5>
                                            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
                                        </Carousel.Caption>
                                    </Carousel.Item>
                                </Carousel>

                            </Col>
                        </Row>
                    </Container>
                </div>
            </div>


        </>
    )
}