import React, { useEffect, useState } from "react";
import Header from "../Header";
import Cards from './card';
import AddGreeting from "./addGreeting";

import Accordion from 'react-bootstrap/Accordion';



export default function Colleagues() {
    const [data, setData] = useState([]);
    const user = localStorage.getItem('user');

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch('https://electronic-queue.ru/aip/selectColleagues.php');
                if (!response.ok) {
                    throw new Error('Ошибка при загрузке данных');
                }
                const jsonData = await response.json();
                setData(jsonData);
            } catch (error) {
                console.error(error);
            }
        };
        fetchData();

    }, []);


    return (
        <>
            <Header />
            <div>
                Поздравление от коллег в компании
            </div>
            {user === 'admin' && (

            <Accordion defaultActiveKey={['1']} alwaysOpen>
                <Accordion.Item eventKey="0">
                    <Accordion.Header>Добавить новое поздравление</Accordion.Header>
                    <Accordion.Body>
                        <AddGreeting/>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            )}

            <Cards data ={data} />

        </>
    )
}
