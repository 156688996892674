import React, { useState, useEffect } from 'react';

export default function Admin() {
    const [user, setUser] = useState(localStorage.getItem('user') || '');

    const handleButtonClick = () => {
        if (user === 'admin') {
            localStorage.removeItem('user');
            setUser('');
        } else {
            localStorage.setItem('user', 'admin');
            setUser('admin');
        }
    };

    useEffect(() => {
        localStorage.setItem('user', user);
    }, [user]);

    return (
        <div>
            <button onClick={handleButtonClick}>
                {user === 'admin' ? 'Удалить запись' : 'Добавить запись'}
            </button>

        </div>
    );
}
