import React from "react";
import Card from 'react-bootstrap/Card';
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";

import '../Colleagues/indexCss.css'

export default function CardShop({data}) {
    return (
        <>
            <Row xs={1} md={1} className="g-4">
                <Col>
                    {data && data.map(item => (
                        <Card className={'cards'} border="info" bg={'success'} text={'white'} key={item.id}>
                            <Card.Body>
                                <Card.Title><h2>Поздравление от: {item.name}</h2></Card.Title>
                                <div>
                                    {(item.typefile === 'image/png' || item.typefile === 'image/jpeg') && (
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <Image  src={'https://electronic-queue.ru/aip/' + item.path} alt={'photo' + item.path} style={{ width: '500px' }} thumbnail />
                                        </div>
                                    )}
                                    {item.typefile === 'video/mp4' && (
                                        <div style={{ width: 'auto', height: '400px', display: 'flex', justifyContent: 'center' }}>

                                            <video controls className={'videclass'}>
                                                <source src={'https://electronic-queue.ru/aip/' + item.path} type="video/mp4" />
                                            </video>

                                        </div>
                                    )}
                                </div>
                                <Card.Text>
                                    {item.text !== 'null' && (
                                        <h4>{item.text}</h4>
                                    )}
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    ))}
                </Col>
            </Row>
        </>
    )
}