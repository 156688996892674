import { useState } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import './cssIndex.css'

export default function Header() {
    const [expand, setExpand] = useState(false);

    const handleToggle = () => {
        setExpand(!expand);
    };
    return (
        <>
            <Navbar expand="lg" className="bg-success mb-3">
                <Container fluid>
                    <Navbar.Brand className={'text'} href="/"><h2>День рождение магазина</h2></Navbar.Brand>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand-${expand}`} onClick={handleToggle} />
                    <Navbar.Collapse id={`offcanvasNavbar-expand-${expand}`} className={`${expand ? 'show' : ''}`}>
                        <Nav className="me-auto">
                            <Nav.Link className={'text'} href="/"><h5>Главная страница</h5></Nav.Link>
                            <Nav.Link className={'text'} href="/colleagues"><h5>Коллеги из компании</h5></Nav.Link>
                            <Nav.Link className={'text'} href="/shop"><h5>Магазин</h5></Nav.Link>

                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
}