import React, { useState } from "react";
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function AddGreetingShop() {

    const [selectedFile, setSelectedFile] = useState(null);
    const [selectedName, setSelectedName] = useState(null);
    const [selectedText, setSelectedText] = useState(null);
    const [fileType, setFileType] = useState('');
    const [isPhotoSelected, setIsPhotoSelected] = useState(false);
    const [isVideoSelected, setIsVideoSelected] = useState(false);


    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedFile(file);
            setIsPhotoSelected(file.type.includes('image'));
            setIsVideoSelected(file.type.includes('video'));
            setFileType(file.type.includes('image') ? 'photo' : 'video');
        } else {
            setSelectedFile(null);
            setIsPhotoSelected(false);
            setIsVideoSelected(false);
            setFileType('');
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();


        const formData = new FormData();
        formData.append('name', selectedName);
        formData.append('text', selectedText);
        formData.append('file', selectedFile);
        formData.append('fileType', fileType);

        try {
            const response = await axios.post('https://electronic-queue.ru/aip/addShop.php', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            if (response.status === 200) {
                console.log('Данные и файл успешно загружены');
            } else {
                console.error('Не удалось загрузить данные и файл');
            }
        } catch (error) {
            console.error('Ошибка:', error);
        }

        console.log(formData);

    };

    return (
        <>
            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                    <Form.Label>Имя поздравителя</Form.Label>
                    <Form.Control type="text" onChange={e => setSelectedName(e.target.value)} placeholder="Иванов Иван" />
                </Form.Group>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                    <Form.Label>Текст поздравления</Form.Label>
                    <Form.Control as="textarea" onChange={e => setSelectedText(e.target.value)} placeholder="Текст поздравления или описания фото или видео" rows={3} />
                </Form.Group>
                <Form.Group controlId="formFilePhoto" className="mb-3">
                    <Form.Label>Добавить фото</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} accept="image/*" disabled={isVideoSelected} />
                </Form.Group>
                <Form.Group controlId="formFileVideo" className="mb-3">
                    <Form.Label>Добавить видео</Form.Label>
                    <Form.Control type="file" onChange={handleFileChange} accept="video/*" disabled={isPhotoSelected} />
                </Form.Group>
                <p>Выбран тип файла: {fileType}</p>
                <Button type="submit">Отправить</Button>
            </Form>
        </>
    )
}
